import React from 'react';
import { Box } from '@mui/material';
export const SFlowView = (props) => {
    return (
        <Box
        className={props.class}
        sx={{
            display: 'flex',
            flexDirection: (props.direction ? props.direction : 'row'),
            gap: (props.space ? props.space : 2),
            alignItems: (props.align ? props.align : 'center'),
        }}>
            {props.children}
        </Box>
    );
};
export const SSpacer = () => {
    return (
        <Box
        sx={{
            display: 'flex',
            flex: 'auto',
        }}>
        </Box>
    );
}