import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, AppBar, Toolbar, MenuItem, Typography } from '@mui/material';
import SLabel from './sui/SLabel';
import { LocaleProvider, LocaleSwitcher, useLocale } from './sui/SLocaleContext';
function MainMenuBar(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleMenuItemClick = (e) => {
        setSelectedIndex(0);
    };
    const handleClose = () => {
        
    };
    const buttonStyle = {
        textTransform: 'none', // Prevent text transformation
    };
    return (
    <AppBar position="static" style={{marginBottom: 20}}>
      <Toolbar>
      <Button style={buttonStyle} color="inherit" component={Link} to="/"><SLabel>home</SLabel></Button>
      <Button style={buttonStyle} color="inherit" component={Link} to="/apps"><SLabel>apps</SLabel></Button>
      <Button style={buttonStyle} color="inherit" component={Link} to="/education"><SLabel>education</SLabel></Button>
      <Button style={buttonStyle} color="inherit" component={Link} to="/research"><SLabel>research</SLabel></Button>
      <Button 
        style={buttonStyle} 
        color="inherit"
        component="a"
        href="https://yujisue.github.io/"
        target="_blank"
        rel="noopener noreferrer"
      >Github</Button>
      <Button 
        style={buttonStyle}
        color="inherit"
        component={Link} to="/publication"
        >
        <SLabel>publication</SLabel>
      </Button>
      {/*<Button style={buttonStyle} color="inherit"><SLabel>contact</SLabel></Button>*/}
      <div style={{ flexGrow: 1 }} />
      <LocaleSwitcher lang="en" style={buttonStyle}/>
      <Typography>/</Typography>
      <LocaleSwitcher lang="ja" style={buttonStyle}/>
      </Toolbar>
    </AppBar>
    );
}

export default MainMenuBar;