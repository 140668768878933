import React, { useState } from 'react';
import { TextField } from '@mui/material';

const STextArea = (props) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <TextField
      label={props.label ? props.label : ''}
      multiline
      rows={props.nrow ? props.nrow : 5} // Sets the default number of rows
      variant="outlined"
      value={value}
      onChange={handleChange}
    />
  );
};

export default STextArea;