import React, {useState} from 'react';
import { Box } from '@mui/material'
import MainMenuBar from './MainMenuBar';
import SLabel from './sui/SLabel';
import { LocaleProvider } from './sui/SLocaleContext';
import contentsData from './material/contents.json';
import "./sui/sui.css"
import "./style.css"
function Home() {
    return(
        <LocaleProvider>
        <MainMenuBar />
        <Box id="root" className="main">
        <Box className="content-header">
            <SLabel className="title">introduction</SLabel>
        </Box>
        <Box className="content-header">
            <SLabel className="title">apps</SLabel>
        </Box>
        <Box className="content-header">
            <SLabel className="title">research</SLabel>
        </Box>
        <Box className="content-header">
            <SLabel className="title">education</SLabel>
        </Box>
        </Box>
        <hr/>
        <Box>
            <p>&copy; 2023 YSebase. All rights reserved.</p>
        </Box>
        </LocaleProvider>
    );
}
export default Home;