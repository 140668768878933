import React, { useState } from 'react';
import {Box, Button} from '@mui/material';
import { LocaleProvider } from '../sui/SLocaleContext';
import { SSpacer, SFlowView } from '../sui/SFlowView';
import SLabel from '../sui/SLabel';
import SSelector from '../sui/SSelector';
import STextArea from '../sui/STextArea';
import '../style.css'

function BioAnnot(props) {
  const [result, setResult] = useState({});
  const [oformat, setOformat] = useState('fa');
  const [species, setSpecies] = useState('human');
  const [qtype, setQtype] = useState('pos');
    const handleChange = (event) => {
        setSpecies(event.target.value);
      };

      const selectSpecies = (sp) => {
        setSpecies(sp);
      };
      const selectOformat = (of) => {
        setOformat(of);
      }
      const selectQueryType = (st) => {
        setQtype(st ? "gene":"pos");
      };
      const getResult = () => {
        console.log(species, ":", qtype);
        
      };
              /* 
    
    useEffect(() => {
        const fetchData = async (mode = '') => {
            try {
              const url = 'http://localhost:9000/test';
                if (mode === 'load') {
                    const response = await fetch(url);
                    const result = await response.json();
                    setData(result);      
                }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
        fetchData('load');
        }, []);
    */


    return(
      <LocaleProvider>
        <Box className='app-view'>
        <SFlowView>
            <Box>
                <Box>
                <SSelector
        selected='worm'
        className='species-selector'
        action={selectSpecies}
        label="species"
        items={[
          {"label":"human (GRCh38.p14)", "value":"human"},
          {"label":"worm (C.elegans, WS290)", "value":"worm"}
        ]}/>
                </Box>
                <Box>
                <STextArea nrow={5}/>
                </Box>
            
        
            </Box>
            <Box alignItems='center'>
            <Button 
            variant="outlined" 
            onClick={getResult}>
                <SLabel>convert</SLabel>
                </Button>
            </Box>
            <Box>
                <Box><SSelector
        selected={oformat}
        action={selectOformat}
        label="oformat"
        items={[
          {"label":"GenBank", "value":"gbk"},
          {"label":"Fasta", "value":"fa"}
        ]}/></Box>
                <Box><STextArea nrow={5}/></Box>
            
            </Box>

            

        </SFlowView>
        </Box>
      </LocaleProvider>
    );
}
export default BioAnnot;