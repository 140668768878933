import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SLabel from './SLabel';
function SExpandPane(props) {
    const [expanded, setExpanded] = useState(props.expanded); // Initial state: closed

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded); // Toggle the state
  };

    return(
            <Accordion
            className={`expane ${props.class}`}
            expanded={expanded}
            onChange={handleChange}
            >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header">
                    <SLabel class="expane-title">{props.title}</SLabel>
                </AccordionSummary>
                <AccordionDetails>
                    {props.children}
                </AccordionDetails>
            </Accordion>
    );
}
export default SExpandPane;