import React, { useState, useEffect } from 'react';
import {Box, Paper} from '@mui/material';
import { LocaleProvider } from '../sui/SLocaleContext';
import MainMenuBar from '../MainMenuBar';
import SLabel from '../sui/SLabel';
import SSearchBox from '../sui/SSearchBox';
import SSelector from '../sui/SSelector';
import {SFlowView, SSpacer} from '../sui/SFlowView';
import AppDetailView from './AppDetailView';
import currentApps from '../material/apps.json';
import "../sui/sui.css";
import "../style.css";
import SCheckBox from '../sui/SCheckBox';

function RefSeqBox() {
  const [oformat, setOformat] = useState('txt');
  const [species, setSpecies] = useState('human');
  const [annot, setAnnot] = useState(false);
  const [atype, setAtype] = useState('');
  
  const changeSelect1 = (sp) => {
    setSpecies(sp);
  };
  const changeSelect2 = (of) => {
    setOformat(of);
    if (of == "gbk") setAnnot(true);
    else setAnnot(false);
  }
  return(
    <Box>
      <SFlowView>
      <SSelector
        selected={species}
        class='species-selector'
        action={changeSelect1}
        label="species"
        items={[
          {"label":"Human (GRCh38.p14)", "value":"human"},
          {"label":"Worm (C.elegans, WS290)", "value":"worm"}
        ]}/>
      <SSpacer/>
      <SSelector
        selected={oformat}
        class='format-selector'
        action={changeSelect2}
        label="oformat"
        items={[
          {"label":"Text", "value":"txt"},
          {"label":"Fasta", "value":"fa"},
          {"label":"GenBank", "value":"gbk"}
        ]}/>
      </SFlowView>
      {annot ?
      <SFlowView>
        <SLabel class="label01">Annotaton:</SLabel>
        <SCheckBox 
        label='gene'
        />
        <SCheckBox 
        label='transcript'
        />
        <SCheckBox 
        label='variant'
        />
      </SFlowView>
       : <div></div>}
      <SSearchBox
      targets={[
        {
          label: 'pos',
          value: 'pos'
        },
        {
          label: 'gene',
          value: 'gene'
        }
      ]}
      target='pos'
      placeholders = {{
        'pos': 'Ex. chr1:12346-23467',
        'gene': 'Ex. , '
      }}
      
        />
    </Box>
  );
};

function RefSeq(props) {
  const appinfo = currentApps[currentApps[0]['refseq']];
  const [result, setResult] = useState({});
  const [param, setParam] = useState({qtype:'pos',oformat:'txt',query:''});
  
  const getResult = () => {
    
    
  };
  useEffect(() => {
    const fetchData = async () => {
        try {
          //const url = 'http:///';
          let url = 'http:/localhost:9000/seq/';
            if (param['qtype'] === 'pos') {

              url += 'genome?';
                const response = await fetch(url);
                const result = await response.json();
                setResult(result); 
            }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    fetchData('');
    }, []);
              /* 
    
    
    */


    return(
      <LocaleProvider>
        <MainMenuBar/>
        <Box className='app-view'>
            <SFlowView class='app-title-header'>
                <SSpacer/>
                <div className='app-title unselectable'>
                    {appinfo.title}
                </div>
                <AppDetailView info={appinfo}/>
                <SSpacer/>
            </SFlowView>
        <SFlowView>
        <SSpacer/>
        <RefSeqBox
        action={getResult}
        value=""
        />
        <SSpacer/>
        </SFlowView>
        <Paper>
        {result.seq ? 
        <div>res</div>
        :<div></div>}
        </Paper>
        </Box>
      </LocaleProvider>      
    );
}
export default RefSeq;