import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  List, ListItem, ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import datList from '../material/nbrp.json';
const NBRP = () => {
  const aStyle = {
    margin : '10px',
  };

  return (
      <Container maxWidth="md" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Typography variant="h3">
            講習会資料
          </Typography>
          {datList.map((item) => (
            <Accordion style={aStyle} key={item.id} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${item.id}a-content`} id={`panel${item.id}a-header`}>
                <Typography variant="h5">
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {item.content.map((content) => (
                  <ListItem>
                  <ListItemText primary={content} />
                  </ListItem>               
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
      </Container>
  );
};
export default NBRP;