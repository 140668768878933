import React, { useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import SLabel from './SLabel'
import "./sui.css"

function SSelector(props) {
    const [selected, setSelected] = useState(props.selected);
    const handleChange = (event) => {
        setSelected(event.target.value);
        if (props.action) props.action(event.target.value);
    };
    return (
        <label
        id={props.id}
        className={`sselector ${props.class?props.class:''}` }>
            <SLabel className="form-label">{props.label}</SLabel>
            <Select  
            value={selected}
            fullWidth
            onChange={handleChange}>
                { props.items.map((item, index) => (
                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    )) }
            </Select>
        </label>
    );
};
export default SSelector;
