import React from 'react';
import { Box, Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import MainMenuBar from '../MainMenuBar';
import SLabel from '../sui/SLabel';
import SCardView from '../sui/SCardView';
import { LocaleProvider } from '../sui/SLocaleContext';
import AppList from '../material/applist.json';
import AppInfos from '../material/apps.json';
import '../sui/sui.css';
import '../style.css';

function AppCard({ prop }) {
    return(
        <Card className="app-card">
            <a href={prop.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div class="horizontal-center-align">
                <img class="app-card-image" src={prop.image} alt={prop.title} ></img>
            </div>
            <div class="horizontal-center-align">
                <div>
                <SLabel class='app-card-title'>
                    {prop.title}
                    </SLabel>
                </div>
                <div>
                </div>
            </div>
            </a>
        </Card>
    );
}
function WebApps({ props }) {
    return(
        <LocaleProvider>
            <MainMenuBar/>
            <Box className="main-view">
                <SCardView>
                    {AppList.map((app, index) => (
                        <AppCard prop={AppInfos[app]} key={index} />
                    ))}
                </SCardView>
            </Box>
        </LocaleProvider>
    );
}
export default WebApps;