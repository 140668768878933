import React, { useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import SLabel from './SLabel';
const SCheckBox = (props) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (props.action) props.action(event.target.checked)
  };

  return (
    <label id={props.id} className="scheck">
        <Checkbox
        checked={checked}
        onChange={handleChange}
        color="primary"
        />
        <SLabel className="form-label">{props.label}</SLabel>
    </label>
  );
};

export default SCheckBox;