import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, Paper, Button } from '@mui/material';
import SLabel from './SLabel';

function STableView(props) {
    const [data, setData] = useState(props.data);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(props.primary);
    const [page, setPage] = useState(props.page ? props.page : 0);
    const [rowsPerPage, setRowsPerPage] = useState(props.nrow ? props.nrow : 20);
    
    console.log(data.cols);


    // Sorting function
    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };
  
    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };
  
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };
  
    // Pagination
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  
    return (
      <Paper className={`stable-view ${props.class}`}>
        <TableContainer>
          <Table>
            <TableHead className="stable-header">
              { 0 < data.cols.length ?
                <TableRow
                className="stable-header-row">
                  { data.cols.map((col, index) => (
                    <TableCell 
                    id={col.id} 
                    key={index}
                    className="stable-header-cell">
                      {col.sortable ? 
                      <TableSortLabel
                      active={orderBy === col.id}
                      direction={orderBy === col.id ? order : "asc"}
                      onClick={() => handleRequestSort(col.id)}
                      >
                        <SLabel>{col.label}</SLabel>
                      </TableSortLabel>
                      : <SLabel>{col.label}</SLabel>
                      }
                      </TableCell>
                  ))
                  }
                </TableRow>
                : <TableRow className="stable-header-row"></TableRow>
                }
            </TableHead>
            <TableBody>
              { 0 < data.rows.length ? stableSort(
                data.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                getComparator(order, orderBy)
              ).map((row, index) => (
                <TableRow key={index} className="stable-row">
                  { row.map((cell, index2) => (
                    <TableCell 
                    key={String(index)+"-"+String(index2)}
                    className="stable-cell"
                    >
                      { data.cols[index2]["type"]==="text" ?
                      <div dangerouslySetInnerHTML={{ __html: cell }} />
                      : (data.cols[index2]["type"]==="button" ?
                      <Button 
                      variant="outlined"
                      onClick={() => { data.cols[index2]["action"](cell) }}
                      ><SLabel>{data.cols[index2]["blabel"]}</SLabel></Button>  
                        :<div></div> )
                      }
                    </TableCell>
                  ))
                  }
                </TableRow>
              ))
              : <div></div>
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={props.rows ? props.rows : [10,20,50]}
          component="div"
          count={data.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
};
export default STableView;