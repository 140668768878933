import React from 'react';
import MainMenuBar from './MainMenuBar'
import { Container, Grid, CardContent,Typography } from '@mui/material';
import eduData from './material/education.json';
import { LocaleProvider, useLocale } from './sui/SLocaleContext';
import SLabel from './sui/SLabel';
function ECard(props) {
    const { locale } = useLocale();
    const cardStyle = {
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }
    const cardContentStyle = {
        padding: '20px'
    }
    return(
        <Grid item key={props.data.id} style={cardStyle} xs={12} sm={6} md={4} lg={3}>
            <Grid container style={cardContentStyle} spacing={1}>
            <Grid item>
                <Typography 
                variant="h5" 
                component="a"
                href={props.data.link}
                target="_blank">
                    {props.data.title[locale]}
                </Typography>
            </Grid>
            <Grid item>
                <Typography color="text.secondary">
                    {props.data.content[locale]}
                </Typography>
            </Grid>
            </Grid>
        </Grid>
    );
}

function Education() {
    
    const containerStyle = {
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '0px',
        marginLeft: '5vw',
        maxWidth: '90vw',
    }
    return(
        <LocaleProvider>
            <MainMenuBar />
            <Grid container style={containerStyle} spacing={3}>
            {eduData.map((doc) => (
                <ECard data={doc}></ECard>
                ))}
            </Grid>
        </LocaleProvider>
    );
}
export default Education;