import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Card, Button, Paper, Typography, InputLabel, IconButton } from '@mui/material';
import { SearchIcon, ViewAgenda, ListSharp } from '@mui/icons-material';
import CategorySearch from '../CategorySearch';
import { SSpacer, SFlowView } from '../sui/SFlowView';
import STableView from '../sui/STableView';
import SCheckBox from '../sui/SCheckBox';
import SSearchBox from '../sui/SSearchBox';
import MainMenuBar from '../MainMenuBar';
import AppDetailView from './AppDetailView';
import SLabel from '../sui/SLabel';
import { LocaleProvider } from '../sui/SLocaleContext';
import AppInfos from '../material/apps.json';
import "../style.css";

function VarSearchBox(props) {
    const searchQuery = (target, value) => {
        if (props.action) props.action(target, value, {});
    };
    const changeState1 = (event) => {

    };
    const changeState2 = (event) => {
        
    };
    
    return(
        <Box>
            <Box>
            <SSearchBox
            class='vsearch-box'
            targets={[
                {label: 'Position', value: 'pos'},
                {label: 'Gene', value: 'gene'},
                {label: 'Variants', value: 'variant'},
                {label: 'Orthologue', value: 'hgene'},
                {label: 'Disease', value: 'disease'}
            ]}
            target='pos'
            action={searchQuery}
            placeholders = {{
                'pos': 'Ex. I:12345678-23456789',
                'gene': 'Ex. WBGene ID, gene name, etc...',
                'variant': 'Ex. WBVar ID, allele name, etc...',
                'hgene': 'Ex. HGNC ID, human gene symbol, etc...',
                'disease': 'Ex. Keywords (cancer, diabetes, etc...)'
            }}
            />
            </Box>
            <Box className="vsearch-option">
                <SCheckBox label='show-all' action={changeState1}/>
                <SCheckBox label='cds-only' action={changeState2}/>
            </Box>
        </Box>
    );
};
function VarDetailView({info}) {
    const [visible, setVisible] = useState(false);
    const togglePane = () => {
        setVisible(!visible);
    };
    return (
        <div className='app-deteail-view' >
        <Button variant="outlined" onClick={togglePane}>
            <SLabel>detail</SLabel>
        </Button>
        {visible && (
            <Paper
            elevation={3}
              sx={{
                position: 'fixed',
                top: '20%',
                right: '20%',
                width: '360px',
                padding: '20px',
                zIndex: 1000, // Ensure it appears above other elements
                backgroundColor: 'white',
              }}
            >
                <Box className="app-desc-body">
                    <Typography
                    className="app-info-description"
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: info.description
                    }}
                    />
                    <h3>Citation</h3>
                    {0 < info.citation.length ?
                      info.citation.map((cit, index) => (
                        <Typography
                        key={index}
                        className="app-info-citation"
                        dangerouslySetInnerHTML={{
                          __html: cit
                        }}
                        />
                        ))
                    :<div></div>}
                    <Button 
                    variant="outlined" 
                    onClick={togglePane}
                    sx={{marginTop: '20px'}}
                    >
                        <SLabel>close</SLabel>
                    </Button>
                </Box>
            </Paper>
          )}
        </div>
      );
}
function VariantMap({svg}) {
    return (
        <div 
        class="var-map" 
        dangerouslySetInnerHTML={{ __html: svg }} 
        />
    );
};
function VariantTable({data}) {
    return(
        <Box>
            <STableView 
            class="var-table"
            page={0}
            data={data}
            />
        </Box>
    );
};
function VarCard(props) {
    const showVerInfo = (idx) => {
        alert("var index: " + String(idx));
    };
    return (
        <Card className="var-card">
            <Box className={`var-title ${props.type}`}>
                <SLabel>{props.type}</SLabel>
                <span>: </span>
                {props.data.query}
                
            </Box>
            {props.data.map?
            <Box sx={{display:"flex", justifyContent:"center"}}><VariantMap svg={props.data.map}/></Box>
            :<div></div>
            }   
            <Box>
                <div className="varlist-label"><SLabel class="varlist">variants</SLabel></div>
                {
                    props.data.variants.length == 0 ?
                    <span>Not found.</span>: 
                    <VariantTable 
                    data={{
                        "cols": [
                            {
                                "id": "vid",
                                "type": "text",
                                "label": "name", 
                                "sortable": false
                            },
                            {
                                "id": "",
                                "type": "text",
                                "label": "positon", 
                                "sortable": false
                            },
                            {
                                "id": "",
                                "type": "text",
                                "label": "type", 
                                "sortable": false
                            },
                            {
                                "id": "",
                                "type": "text",
                                "label": "substitution", 
                                "sortable": false
                            },
                            {
                                "id": "",
                                "type": "text",
                                "label": "annotation", 
                                "sortable": false
                            },
                            {
                                "type": "button",
                                "blabel": "detail",
                                "action": showVerInfo
                            }
                        ],
                        "rows": props.data.variants
                    }}/>
                }
            </Box>
        </Card>
    );
};
function VarResult(props) {
    //console.log(props.data);
    const changeMode = (event) => {

    };
    return(
        <Box className="vresult-area">
            <Box className="vresult-view">
                <SSpacer/>
                <IconButton 
                sx={{fontSize: '60px'}}
                onClick={changeMode} 
                color="primary" 
                aria-label="list">
                    <ListSharp />
                </IconButton>
                <IconButton 
                sx={{fontSize: '60px'}}
                onClick={changeMode} 
                color="primary" 
                aria-label="list">
                    <ViewAgenda />
                </IconButton>
            </Box>
            <Box>
            <Box className="search-results">
                { props.data.result && 0 < props.data.result.length ?
                  props.data.result.map((res, index) => (
                    <VarCard type={props.data.qtype} data={res} key={index} />
                ))
                : <div></div>
                } 
            
            </Box>
            </Box>
        </Box>
    );
}

function VariantViewer(props) {
    const appinfo = AppInfos['varview'];
    const defaultParams = new URLSearchParams(useLocation().varview);
    //console.log(defaultParams);
    const [param, setParam] = useState([]);
    const [result, setResult] = useState({});
    const getResult = (qtype, query, opts) => {
        setParam([qtype, query, opts]);
    };
    const fetchData = async () => {
        try {
            if (param[0] && param[1]) {
                const que = param[1].replace(/,|\t/g, ' ');
                const url = `https://api.ys-ebase.net/variant/${param[0]}?query=${que}`;
                document.body.classList.add('waiting');
                const response = await fetch(url);
                const res = await response.json();
                //console.log(res);
                document.body.classList.remove('waiting');
                if (res.status === 'OK') setResult(res.result);
                else alert(res.result.err); 
            }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [param]);
    
    return(
      <LocaleProvider>
        <MainMenuBar/>
        <Box className='app-view'>
            <SFlowView class='app-title-header'>
                <SSpacer/>
                <div class='app-title unselectable'>
                    {appinfo.title}
                </div>
                <AppDetailView info={appinfo}/>
                <SSpacer/>
            </SFlowView>
            <Box className="search-box-area">
                <VarSearchBox
                action={getResult}
                value=""/>
            </Box>
            <Box className="search-results">
                <VarResult data={result}/>
            </Box>
        </Box>
      </LocaleProvider>
    );
};
export default VariantViewer;