import React, { useState } from 'react';
import { Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import SLabel from './SLabel';
import "./sui.css"
const SRadioButton = (props) => {
    const [value, setValue] = useState(props.selected);
    const handleChange = (event) => {
        setValue(event.target.value);
        props.action(event.target.value);
    };
  
    return (
        <RadioGroup value={value} onChange={handleChange}>
                { props.options.map((opt, index) => (
                    <label key={index}>
                        <Radio value={opt.value}/>
                        <SLabel>{opt.label}</SLabel>
                    </label>
                    )) }
            </RadioGroup>
    );
};

export default SRadioButton;