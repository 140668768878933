import MainMenuBar from './MainMenuBar'
import { LocaleProvider } from './sui/SLocaleContext';
import SCardView from './sui/SCardView';
import SSelector from './sui/SSelector';
import SCheckBox from './sui/SCheckBox';
import SRadioButton from './sui/SRadioButton';
import STextBox from './sui/STextField';
import STextArea from './sui/STextArea';
import SSearchBox from './sui/SSearchBox';
import SDatePicker from './sui/SDatePicker';
import SExpandPane from './sui/SExpandPane';

import { React, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Container,
} from '@mui/material';
import './style.css'

const columns = [
  { id: 'id', label: 'ID', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'age', label: 'Age', minWidth: 50 },
];

const data = [
  { id: 1, name: 'John Doe', age: 25 },
  { id: 2, name: 'Jane Smith', age: 30 },
  // Add more data as needed
];

const PaginatedTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.id}>{row[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};


function Debug() {
  const [result, setResult] = useState(null);
  const showValue = (val) => {
    console.log("Parent function ", val);
    setResult(val);
  };
    return(
        <LocaleProvider>
            <MainMenuBar />
            <SExpandPane title="Parameters" expanded={true}>
            <SDatePicker />
            <SSearchBox
            action={showValue}
            />
            </SExpandPane>
              
            {result?
            <p>{result}</p>
            : <div></div>}
            <STextBox
            action={showValue}
            />
            <STextArea
            nrow={10}
            />
            <SCheckBox
            action={showValue}
            label="check"
            />
            <SRadioButton
            action={showValue}
            selected={1}
            options={[
              {
                "label":"option1",
                "value":1
              },
              {
                "label":"option2",
                "value":2
              }

            ]}
            />
            <SSelector 
            class="sel1"
            label="selector"
            selected={200}
            action={showValue}
            items={[
              {
                "value":100,
                "label":"100"
              },
              {
                "value":200,
                "label":"200"
              }
            ]}
            />
            <Container style={{paddingLeft:'5vw',paddingRight:'5vw',paddingTop:'2.5vh'}}>
              <SCardView 
              maxWidth={300} 
              data={[{
                "title": {"en": "Card1", "ja":"サンプル１"},
                "description": "descripton",
                "image": null
              }]}/>
              <PaginatedTable />
            </Container>
        </LocaleProvider>
    );
}

export default Debug;