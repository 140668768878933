import React, { createContext, useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Button } from '@mui/material';
import SLabel from './SLabel';
export const LocaleContext = createContext();
export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = React.useState(navigator.language.split("-")[0]);
  const changeLocale = (newLang) => {
    //console.log(newLang);
    setLocale(newLang);
  };
  return (
    <LocaleContext.Provider value={{ locale, changeLocale }}>
      <IntlProvider locale={locale}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
export const LocaleSwitcher = (props) => {
  const {locale, changeLocale} = useLocale();
  const switchLocale = (loc) => {
    changeLocale(loc);
  }
  return (
    <Button style={props.style} color="inherit" onClick={()=>{switchLocale(props.lang);}}><SLabel>{props.lang}</SLabel></Button> 
  );
}
export const useLocale = () => { 
  const context = useContext(LocaleContext);
  return context;
};