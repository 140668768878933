import React, { useState, useEffect } from 'react';
import { Box, Paper, Button, Typography } from '@mui/material';
import SLabel from '../sui/SLabel';
import "../sui/sui.css";
import "../style.css";

function AppDetailView({info}) {
    const [visible, setVisible] = useState(false);
    const togglePane = () => {
        setVisible(!visible);
    };
    return (
        <div className='app-deteail-view' >
        <Button variant="outlined" onClick={togglePane}>
            <SLabel>description</SLabel>
        </Button>
        {visible && (
            <Paper
            elevation={3}
              sx={{
                position: 'fixed',
                top: '20%',
                right: '20%',
                width: '360px',
                padding: '20px',
                zIndex: 1000, // Ensure it appears above other elements
                backgroundColor: 'white',
              }}
            >
                <Box className="app-desc-body">
                    <h3>About {info.title}</h3>
                    <Typography
                    className="app-info-description"
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: info.description
                    }}
                    />
                    <h3>Citation</h3>
                    {0 < info.citation.length ?
                      info.citation.map((cit, index) => (
                        <Typography
                        key={index}
                        className="app-info-citation"
                        dangerouslySetInnerHTML={{
                          __html: cit
                        }}
                        />
                        ))
                    :<div></div>}
                    <Button 
                    variant="outlined" 
                    onClick={togglePane}
                    sx={{marginTop: '20px'}}
                    >
                        <SLabel>close</SLabel>
                    </Button>
                </Box>
            </Paper>
          )}
        </div>
      );
}
export default AppDetailView;