import React, { useState } from 'react';
import { Box, TextField, Button, FormControl, FormGroup } from '@mui/material';
import SLabel from './SLabel';
import SSelector from './SSelector';
import './sui.css';
const SSearchBox = (props) => {  
  const [target, setTarget] = useState(props.target);
  const [ph, setPh] = useState(props.placeholders ? props.placeholders[props.target] : 'Search');
  const [value, setValue] = useState('');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const changeSelect = (value) => {
      setTarget(value);
      if (props.placeholders) setPh(props.placeholders[value]);
    };
    const handleSearch = () => {
      if (props.action) props.action(target, value);
    };
  
    return (
        <Box
        className={`ssearch-box ${props.class}`}
        component="form"
        display="flex"
        sx={{ padding: '16px' }}
        >
        <TextField
          label="Search"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          placeholder={ph}
          value={value}
          className="query-text"
          onChange={handleChange}
          sx={{ height: '56px' }}
        />
        {props.targets ? 
        <SSelector
        selected={target}
        action={changeSelect}
        items={props.targets}
        class='target-select'
        />
        : <span></span>}
        <Button
          variant="contained"
          onClick={handleSearch}
          color="primary"
          className="submit"
          sx={{ height: '56px' }}
        >
          <SLabel>search</SLabel>
        </Button>
    </Box>
    );
};
export default SSearchBox;
