import React from "react";
import { Box, Grid } from "@mui/material";

function SCardView(props) {
  return (
    <Box
    display="flex"
    alignItems="stretch" 
    className={`scard-view ${props.class}`}>
      {props.children}
    </Box>
  );
};
export default SCardView;