import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  List, ListItem, ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainMenuBar from './MainMenuBar'
import { LocaleProvider } from './sui/SLocaleContext';
import SLabel from './sui/SLabel';
import pubList from './material/pub.json';
const Publication = () => {
  const aStyle = {
    margin : '10px',
  };

  return (
    <LocaleProvider>
      <MainMenuBar />
      <Container maxWidth="md" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Typography variant="h3">
            <SLabel>publist</SLabel>
          </Typography>
          {pubList.map((item) => (
            <Accordion style={aStyle} key={item.id} defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${item.id}a-content`} id={`panel${item.id}a-header`}>
                <Typography variant="h5">
                  <SLabel>{item.title}</SLabel>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {item.content.map((article) => (
                  <ListItem>
                  <ListItemText primary={article} />
                  </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
      </Container>
    </LocaleProvider>
  );
};
export default Publication;