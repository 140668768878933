import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';

const STextField = (props) => {
    const [value, setValue] = useState(props.value ? props.value : '');
    const handleChange = (event) => {
        setValue(event.target.value);
        if (props.action) props.action(event.target.value);
    };
    return (
        <label 
        className={`stext-field ${props.class?props.class:''}`}
        >
            <TextField
            id={props.id}
            InputLabelProps={{ shrink: true }}
            label={props.label}
            placeholder={props.placeholder}
            variant="outlined"
            value={value}
            fullWidth
            onChange={handleChange}
            sx={{ 
                '.MuiFormControl-root': {
                  'vertical-align': 'center',
                },
              }} 
            />

        </label>
        
    );
};
export default STextField;