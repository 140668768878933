import React, { useState, useEffect } from 'react';
import { Box, Card } from '@mui/material';
import { SSpacer, SFlowView } from '../sui/SFlowView';
import SSearchBox from '../sui/SSearchBox';
import MainMenuBar from '../MainMenuBar';
import AppDetailView from './AppDetailView';
import SLabel from '../sui/SLabel';
import { LocaleProvider } from '../sui/SLocaleContext';
import AppInfos from '../material/apps.json';
import "../style.css";

function BalSearchBox(props) {
    const [allbal, setAllbal] = useState(false);
    const searchQuery = (target, value) => {
        if (props.action) props.action(target, value, {
            'all' : allbal
        });
    };
    return(
        <Box>
            <Box>
            <SSearchBox
            class='bsearch-box'
            targets={[
                {label: 'Position', value: 'pos'},
                {label: 'Gene', value: 'gene'},
                {label: 'Variants', value: 'variant'}
            ]}
            target='pos'
            action={searchQuery}
            placeholders = {{
                'pos': 'Ex. I:12345678-23456789',
                'gene': 'Ex. WBGene ID, gene name, etc...',
                'variant': 'Ex. WBVar ID, allele name, etc...'
            }}
            />
            </Box>
            <Box>
            </Box>
        </Box>
    );
};
function BalCard({prop}) {
    return (
        <Card className="bal-card">
            <Box className={`bal-title ${prop.qtype}`}>
                <SLabel>{prop.qtype}</SLabel>
                <span>: </span>
                {prop.query}
            </Box>
            <Box className="bal-result">
                <SLabel>balancer</SLabel>:<br/>
                {
                    prop.out.length == 0 ?
                    "Not found."
                    : prop.out.join(", ")
                }
            </Box>
        </Card>
    );
};
function BalancerSelect(props) {
    const [param, setParam] = useState([]);
    const [result, setResult] = useState([]);
    const appinfo = AppInfos['balview'];
    const getResult = (qtype, query, opts) => {
        setParam([qtype, query, opts]);
    };
    const fetchData = async () => {
        try {
            if (param[0] && param[1]) {
                const que = param[1].replace(/,|\t|;/g, ' ');
                //console.log(que);
                document.body.classList.add('waiting');
                const url = `https://api.ys-ebase.net/balancer/${param[0]}?query=${que}`;
                const response = await fetch(url);
                const res = await response.json();
                //console.log(res);
                document.body.classList.remove('waiting');
                if (res.status === "OK") setResult(res.result);
                else alert(res.result.err);
            }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [param]);
    
    return(
      <LocaleProvider>
        <MainMenuBar/>
        <Box className='app-view'>
            <SFlowView class='app-title-header'>
                <SSpacer/>
                <div className='app-title unselectable'>
                    {appinfo.title}
                </div>
                <AppDetailView info={appinfo}/>
                <SSpacer/>
            </SFlowView>
        <Box className="search-box-area" >
        <BalSearchBox
        action={getResult}
        value=""
        />
        </Box>
        { 0 < result.length ? 
        <Box className="search-results">
        {result.map((res, index) => (
            <BalCard prop={res} key={index} />
        ))}
        </Box>
        :<div></div>
        }
        </Box>
      </LocaleProvider>
    );
};
export default BalancerSelect;