import React from 'react';
import { Card, Container, Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainMenuBar from './MainMenuBar'
import resData from './material/research.json';
import SLabel from './sui/SLabel';
import { LocaleProvider, useLocale } from './sui/SLocaleContext';

function RCard(props) {
    const { locale } = useLocale();
    const cardStyle = {
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }
    const cardContentStyle = {
        padding: '20px'
    }
    return(
        <Card key={props.data.id} style={cardStyle} xs={12} sm={6} md={4} lg={3}>
            <Grid container style={cardContentStyle} direction="column" spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography 
                variant="h5" 
                component="a"
                href={props.data.link}
                target="_blank">
                    {props.data.title[locale]}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography color="text.secondary">
                    {props.data.content[locale]}
                </Typography>
            </Grid>
            </Grid>
        </Card>
    );
}

function Research() {
    const aStyle = {
        margin : '10px',
      };

    const containerStyle = {
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '0px',
        marginLeft: '5vw',
        maxWidth: '90vw',
    }
    return(
        <LocaleProvider>
            <MainMenuBar />
            <Container style={containerStyle} >
            {Object.entries(resData).map(([key, value]) => (
                <Accordion style={aStyle} key={key} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${key}a-content`} id={`panel${key}a-header`}>
                  <Typography variant="h5">
                    <SLabel>{key}</SLabel>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {value.map((elem) => (
                    <RCard data={elem}></RCard>              
                    ))}
                </AccordionDetails>
              </Accordion>


                
                ))}
            </Container>
        </LocaleProvider>
    );
    /*
    return(
        <LocaleProvider>
            <MainMenuBar />
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
                >
                    <Typography>Genome analysis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        You can search the detected variants in <Link to="/research/cevar" target="_blank">viewer</Link>.<br/>
                        
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
                >
                    <Typography>Image analysis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Link to="./cevar">
                    <Typography variant="body2" color="text.secondary">
                        Variant viewer
                    </Typography>
                    </Link>
                </AccordionDetails>
            </Accordion>
        </LocaleProvider>
    );
    */
}
export default Research;