import React from 'react';
import localeData from './locale.json';
import { useLocale } from './SLocaleContext';
function SLabel(props) {
    const { locale, changeLocale } = useLocale();
    const setText = (txt) => {
        if (localeData[txt] && localeData[txt][locale]) {
            return localeData[txt][locale];
        }
        else { return txt; }
    }
    return (
      <span className={props.class} style={props.style}>
        {setText(props.children)}
      </span>
    );
}
export default SLabel;