import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import AppsCenter from './app/AppsCenter';
import Education from './Education';
import NBRP from './app/Nbrp';
import Research from './Research';
import Publication from './Pub';
import RefSeq from './app/RefSeq';
import BioAnnot from './app/BioAnnot';
import BalancerSelect from './app/BalancerSelect';
import VariantViewer from './app/VariantViewer';

import Debug from './Debug';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route Component={Home} />  
      <Route path="/" element={<Home />} />
      <Route path="/apps" element={<AppsCenter />} />
      <Route path="/apps/refseq" element={<RefSeq />} />
      <Route path="/apps/bioannot" element={<BioAnnot />} />
      <Route path="/apps/balview" element={<BalancerSelect />} />
      <Route path="/apps/varview" element={<VariantViewer />} />
      <Route path="/education" element={<Education />} />
      <Route path="/education/nbrp" element={<NBRP />} />
      <Route path="/research" element={<Research />} />
      <Route path="/research/cevar" element={<VariantViewer />} />
      <Route path="/publication" element={<Publication />} />
      <Route path="/debug" element={<Debug />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
